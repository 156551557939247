import axios from "axios";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import {
  ADMIN,
  ADMIN_REASURANSI,
  BANK,
  BANK_CABANG,
  SUPER_ADMIN
} from "constants/roles.constant";
import { get, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiSignIn } from "services/AuthService";
import { getListRegionalBranch } from "services/ClaimService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { initialState, setUser } from "store/auth/userSlice";
import { setProjectId, setRegionalBankList } from "store/base/commonSlice";
import { updateLastFilterValues } from "store/dataPolis/dataSlice";
import {
  fetchCentralBranch,
  fetchInsuranceCompanyList,
  fetchRegionalBranch,
} from "utils/common";
import { fetchCounterDataPending } from "utils/counter";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const { lastFilterValues } = useSelector((state) => state.dataPolis.data);

  const API_URL_BY_ENV = {
    development: appConfig.apiDevPrefix,
    production: appConfig.apiPrefix,
  };

  const signIn = async ({ email, password }) => {
    try {
      const resp = await apiSignIn({ email, password });

      if (resp.data) {
        const { token } = {
          token: resp.data.data.access_token,
        };

        const config = {
          headers: { Authorization: resp.data.data.access_token },
        };

        const getUserByEmail = await axios.get(
          `${API_URL_BY_ENV[process.env.REACT_APP_API_ENV] || appConfig.apiPrefix
          }/users/profile/user-by-email/${email}`,
          config
        );

        if (getUserByEmail.data) {
          const isIndividual = get(getUserByEmail, "data.data.is_individual")
          const roleName = getUserByEmail.data.data.role_name;
          const role = roleName
          const user = !isNull(isIndividual) ? isIndividual : getUserByEmail.data.data.id;
          const projectId = get(getUserByEmail, "data.data.project_uuid");

          const dataUser = {
            refresh_token: resp.data.data.refresh_token,
            email: email,
            userName: getUserByEmail.data.data.fullname,
            authority: [role],
            avatar: getUserByEmail.data.data.path_avatar,
            project_id: getUserByEmail.data.data.project_id,
            project_uuid: getUserByEmail.data.data.project_uuid,
            user_reinsurance: getUserByEmail.data.data.user_reinsurance,
            user,
            isIndividual: isIndividual,
            isComplete:
              get(getUserByEmail, "data.data.is_complete") === 1 ? true : false,
            ...(await checkIfCentralBranch({
              role,
              user: getUserByEmail.data.data.id,
              projectId,
              isIndividual: isIndividual,
              dataUser: get(getUserByEmail, "data.data")
            })),
            ...(await checkIfRegionalBranch({
              role,
              user: getUserByEmail.data.data.id,
              projectId,
              isIndividual: isIndividual,
              dataUser: get(getUserByEmail, "data.data")
            })),
          };

          if (role === SUPER_ADMIN) {
            dataUser.isComplete = true;
          }

          checkIfUserReinsurance({
            roles: dataUser.authority,
            dataUser,
          });

          fetchCentralBranch({
            dispatch,
            role,
            projectId,
            isIndividual: isIndividual,
            dataUser: get(getUserByEmail, "data.data")
          });

          fetchRegionalBranch({
            dispatch,
            role,
            projectId,
            isIndividual: isIndividual,
            dataUser: get(getUserByEmail, "data.data")
          });

          fetchInsuranceCompanyList({
            dispatch,
            projectId,
          });

          fetchCounterDataPending({
            dispatch,
            projectId: getUserByEmail.data.data.project_id,
            projectUUID: getUserByEmail.data.data.project_uuid,
            role,
            user: getUserByEmail.data.data.id,
          });

          dispatch(setProjectId(get(getUserByEmail, "data.data.project_uuid")));
          dispatch(onSignInSuccess(token));
          dispatch(setUser(dataUser));
          dispatch(
            updateLastFilterValues({
              ...lastFilterValues,
              pusat_id: dataUser.pusat_id,
              cabang_id: dataUser.cabang_id,
            })
          );
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      if (errors?.response?.status === 500) {
        return {
          status: "failed",
          message: "Request failed with status code 500",
        };
      }

      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch({
      type: "RESET_APP",
    });
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  const checkIfCentralBranch = async ({ role, user, projectId }) => {
    try {
      if (![BANK].includes(role)) {
        return {};
      }

      const response = await getListRegionalBranch({
        project_id: projectId,
        pusat_id: user,
      });

      const result = get(response, "data.data", []);
      dispatch(setRegionalBankList(result));

      return {
        pusat_id: result[0]?.pusat_id,
      };
    } catch (error) {
      return {};
    }
  };

  const checkIfRegionalBranch = async ({ role, user, projectId, isIndividual, dataUser }) => {
    try {

      if ([BANK].includes(role)) {
        return {
          pusat_id: user,
        };
      }

      if (![BANK_CABANG].includes(role)) {
        return {};
      }

      if (!isNull(isIndividual) && [BANK_CABANG].includes(role)) {
        const regionalBank = get(dataUser, "cabang")
        const centralBank = get(dataUser, 'wilayah')

        const result = [{
          ...regionalBank,
          pusat_id: centralBank.id,
        }]

        dispatch(setRegionalBankList(result));

        return {
          cabang_id: regionalBank?.id,
          pusat_id: centralBank?.id,
        };
      }

      const response = await getListRegionalBranch({
        project_id: projectId,
        cabang_id: user,
      });
      const result = get(response, "data.data", []);

      const updatedResult = result.map(item => ({
        ...item,
        pusat_id: item.wilayah_id ? item.wilayah_id : item.pusat_id,
      }));

      dispatch(setRegionalBankList(updatedResult));

      return {
        cabang_id: updatedResult[0]?.id,
        pusat_id: updatedResult[0]?.pusat_id,
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const checkIfUserReinsurance = async ({ roles, dataUser }) => {
    if (roles.includes(ADMIN) && dataUser.user_reinsurance) {
      dataUser.authority = [...roles, ADMIN_REASURANSI];
    } else {
      dataUser.authority = roles;
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
