// import { THEME_ENUM } from "constants/theme.constant";

import appConfig from "./app.config";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

const themeConfigs = {
  bib: {
    iconNavColor: "#FAFAFA", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
    themeHexColor: "#0ea5e9", // Value => HEX Code (Untuk global color dengan format hex code)
    themeColor: "sky", // Value => sesuai dengan tailwind e.g sky, red, dll
    tableColor: "sky", // Value => sesuai dengan tailwind e.g sky, red, dll
    direction: "ltr", // Value => ltr/rtl
    mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
    primaryColorLevel: 500, // Value => 50-900
    tableColorLevel: 500, // Value => 50-900
    cardBordered: true,
    panelExpand: false,
    controlSize: "md",
    navMode: "themed",
    layout: {
      type: "modern",
      sideNavCollapse: false,
    },
    locale: "id",
  },
  vai: {
    iconNavColor: "#FAFAFA", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
    themeHexColor: "#FF1C00", // Value => HEX Code (Untuk global color dengan format hex code)
    themeColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
    tableColor: "red", // Value => sesuai dengan tailwind e.g sky, red, dll
    direction: "ltr", // Value => ltr/rtl
    mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
    primaryColorLevel: 600, // Value => 50-900
    tableColorLevel: 300, // Value => 50-900
    cardBordered: true,
    panelExpand: false,
    controlSize: "md",
    navMode: "themed",
    layout: {
      type: "modern",
      sideNavCollapse: false,
    },
    locale: "id",
  },
  mhib: {
    iconNavColor: "#171717", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
    themeHexColor: "#f59e0b", // Value => HEX Code (Untuk global color dengan format hex code)
    themeColor: "amber", // Value => sesuai dengan tailwind e.g sky, red, dll
    tableColor: "amber", // Value => sesuai dengan tailwind e.g sky, red, dll
    direction: "ltr", // Value => ltr/rtl
    mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
    primaryColorLevel: 500, // Value => 50-900
    tableColorLevel: 500, // Value => 50-900
    cardBordered: true,
    panelExpand: false,
    controlSize: "md",
    navMode: "themed-dark",
    layout: {
      type: "modern",
      sideNavCollapse: false,
    },
    locale: "id",
  },
  default: {
    iconNavColor: "#FAFAFA", // Value => HEX Code (Untuk warna icon navigation pada sidebar)
    themeHexColor: "#0ea5e9", // Value => HEX Code (Untuk global color dengan format hex code)
    themeColor: "sky", // Value => sesuai dengan tailwind e.g sky, red, dll
    tableColor: "sky", // Value => sesuai dengan tailwind e.g sky, red, dll
    direction: "ltr", // Value => ltr/rtl
    mode: "light", // Value => light (untuk side bar warna putih) dan dark (untuk side bar warna hitam)
    primaryColorLevel: 500, // Value => 50-900
    tableColorLevel: 500, // Value => 50-900
    cardBordered: true,
    panelExpand: false,
    controlSize: "md",
    navMode: "themed",
    layout: {
      type: "modern",
      sideNavCollapse: false,
    },
    locale: "id",
  },
};

export const themeConfig =
  themeConfigs[appConfig.appProject] || themeConfigs.default;
